// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-gallery-benches-tsx": () => import("./../../../src/pages/gallery/benches.tsx" /* webpackChunkName: "component---src-pages-gallery-benches-tsx" */),
  "component---src-pages-gallery-civic-tsx": () => import("./../../../src/pages/gallery/civic.tsx" /* webpackChunkName: "component---src-pages-gallery-civic-tsx" */),
  "component---src-pages-gallery-double-memorials-tsx": () => import("./../../../src/pages/gallery/double-memorials.tsx" /* webpackChunkName: "component---src-pages-gallery-double-memorials-tsx" */),
  "component---src-pages-gallery-etchings-tsx": () => import("./../../../src/pages/gallery/etchings.tsx" /* webpackChunkName: "component---src-pages-gallery-etchings-tsx" */),
  "component---src-pages-gallery-granite-colors-tsx": () => import("./../../../src/pages/gallery/granite-colors.tsx" /* webpackChunkName: "component---src-pages-gallery-granite-colors-tsx" */),
  "component---src-pages-gallery-hmong-memorials-tsx": () => import("./../../../src/pages/gallery/hmong-memorials.tsx" /* webpackChunkName: "component---src-pages-gallery-hmong-memorials-tsx" */),
  "component---src-pages-gallery-multiple-memorials-tsx": () => import("./../../../src/pages/gallery/multiple-memorials.tsx" /* webpackChunkName: "component---src-pages-gallery-multiple-memorials-tsx" */),
  "component---src-pages-gallery-single-memorials-tsx": () => import("./../../../src/pages/gallery/single-memorials.tsx" /* webpackChunkName: "component---src-pages-gallery-single-memorials-tsx" */),
  "component---src-pages-gallery-slant-memorials-tsx": () => import("./../../../src/pages/gallery/slant-memorials.tsx" /* webpackChunkName: "component---src-pages-gallery-slant-memorials-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-staff-tsx": () => import("./../../../src/pages/staff.tsx" /* webpackChunkName: "component---src-pages-staff-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */)
}

